import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      {/* Contact Info */}
      <div className="contact-info">
        <p>
          Email: <a href="mailto:renzo@startupp.ai">renzo@startupp.ai</a> |
          LinkedIn:{" "}
          <a
            href="https://www.linkedin.com/in/renzo-dupont-b9797941/"
            target="_blank"
            rel="noopener noreferrer"
          >
            My LinkedIn
          </a>
        </p>
      </div>
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
