import React from "react";
import Typewriter from "typewriter-effect";

function CodeEffect({ arr }) {
  return (
    <div className="code-effect-container">
      <div className="code-effect">
        <Typewriter
          options={{
            strings: arr,
            autoStart: true,
            loop: true,
            deleteSpeed: 50,
            delay: 75,
          }}
        />
      </div>
    </div>
  );
}

export default CodeEffect;
