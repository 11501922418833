import React from "react";
import SEO from "./SEO";
import "../App.css";
import Menu from "./Menu";
import { motion } from "framer-motion";
import profileImg from "./me.jpeg";
import Footer from "./Footer";

function About() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i = 1) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
      },
    }),
  };

  return (
    <>
      <SEO />
      <Menu />
      <div className="about-me-container">
        <motion.img
          src={profileImg}
          alt="Perfil"
          className="profile-image"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={1}
        />

        <motion.h1
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={2}
        >
          Sobre Mí
        </motion.h1>

        <motion.h2
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={3}
        >
          Experto en Implementación de IA & Ingeniero Senior en Ciencias de la
          Computación
        </motion.h2>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={4}
        >
          ¡Bienvenido! Soy un Experto en Implementación de IA e Ingeniero Senior
          en Ciencias de la Computación con más de 16 años de experiencia en
          desarrollo de software, gestión de proyectos y liderazgo en TI. Mi
          pasión radica en aprovechar la inteligencia artificial y la
          automatización para transformar negocios e impulsar la innovación.
        </motion.p>

        {/* Camino de Innovación */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={5}
        >
          Un Camino de Innovación
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={6}
        >
          Mi carrera ha estado dedicada a explorar la vanguardia de la
          tecnología. Desde el principio, reconocí el poder transformador de la
          IA y, desde entonces, me he especializado en implementar algoritmos
          avanzados que automatizan procesos, mejoran la analítica y optimizan
          la toma de decisiones. Mi trabajo abarca desde startups hasta grandes
          empresas, siempre enfocado en ofrecer soluciones escalables y
          adaptables.
        </motion.p>

        {/* Hito: Algoritmo de Atribución de Pacientes */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={7}
        >
          Innovando en Soluciones de IA
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={8}
        >
          En 2020, inicié el desarrollo de un algoritmo de Atribución de
          Pacientes que revolucionó la forma en que los proveedores de salud
          comprenden e interactúan con sus pacientes. Al integrar la API de
          OpenAI y crear lógica personalizada, mejoré la precisión y eficiencia
          del algoritmo. Esta innovación no solo mejoró el cuidado del paciente,
          sino que también optimizó las operaciones, estableciendo un nuevo
          estándar en la analítica de salud.
        </motion.p>

        {/* Hito: Algoritmo de Comparación de Direcciones */}
        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={9}
        >
          Reconociendo la necesidad de soluciones rentables, desarrollé un
          algoritmo personalizado de comparación de direcciones que opera sin
          depender de servicios externos. Este enfoque redujo significativamente
          los costos operativos manteniendo una alta precisión en la validación
          y comparación de datos, permitiendo a las empresas una mayor autonomía
          y control sobre sus procesos.
        </motion.p>

        {/* Experiencia Profesional */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={10}
        >
          Experiencia Profesional
        </motion.h3>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={11}
        >
          <li>
            <strong>Implementación y Optimización de IA:</strong> Especializado
            en integrar herramientas de IA en operaciones existentes, haciendo
            que la tecnología avanzada sea accesible y rentable. Proveo prompts
            optimizados para ayudar a las empresas a adoptar IA sin
            complicaciones.
          </li>
          <li>
            <strong>Desarrollo de Algoritmos Personalizados:</strong> He creado
            algoritmos como el sistema de Atribución de Pacientes y herramientas
            de comparación de direcciones, adaptados a necesidades y desafíos
            específicos de cada negocio.
          </li>
          <li>
            <strong>Sistemas Salesforce y ERP:</strong> Amplia experiencia en
            desarrollo en Salesforce, incluyendo integraciones y mejoras como
            Einstein Analytics, optimizando plataformas CRM para mejorar la
            interacción con el cliente y simplificar operaciones.
          </li>
          <li>
            <strong>Desarrollo Full-Stack:</strong> Dominio en Microsoft .NET,
            Node.js, React, entre otros, creando soluciones completas que
            responden a necesidades empresariales únicas. Mi experiencia
            full-stack asegura que cada capa de la aplicación funcione en
            armonía.
          </li>
        </motion.ul>

        {/* Liderazgo y Colaboración */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={12}
        >
          Liderazgo y Colaboración
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={13}
        >
          A lo largo de mi carrera, he sido no solo un desarrollador práctico,
          sino también un líder y gerente de apoyo. En Altimetrik, desempeñé el
          rol de líder de equipo y desarrollador senior en Salesforce, liderando
          el Proyecto GEMINI desde su concepción hasta el despliegue. Mis
          responsabilidades incluían:
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={14}
        >
          <li>
            <strong>Gestión de Proyectos:</strong> Supervisión de todas las
            fases de desarrollo, asegurando entregas puntuales y de alta
            calidad.
          </li>
          <li>
            <strong>Liderazgo de Equipo:</strong> Mentoría a desarrolladores
            junior y fomento de un ambiente colaborativo enfocado en el
            aprendizaje continuo.
          </li>
          <li>
            <strong>Colaboración en Proyectos Cruzados:</strong> Apoyo en
            proyectos clave como Campaña a Lead y Lead a Oportunidad, revisando
            código e integrando soluciones en distintas plataformas.
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={15}
        >
          Implementando metodologías Agile, optimicé procesos de desarrollo,
          mejoré la eficiencia del equipo y elevé la calidad del producto. Mi
          experiencia en gestión incluye el soporte a equipos pequeños y
          medianos como recurso técnico, guiándolos a través de desafíos
          complejos y ayudándolos a alcanzar sus objetivos.
        </motion.p>

        {/* Perspectiva Global */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={16}
        >
          Perspectiva Global
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={17}
        >
          Ser fluido en inglés y nativo en español me permite navegar en
          entornos multiculturales sin dificultad. Esta habilidad lingüística
          mejora la comunicación con equipos y clientes diversos, fomentando
          relaciones sólidas y colaboraciones exitosas a nivel global.
        </motion.p>

        {/* Enfoque Centrado en el Cliente */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={18}
        >
          Enfoque Centrado en el Cliente
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={19}
        >
          Creo en un enfoque personalizado para las soluciones tecnológicas.
          Comprendiendo que cada negocio es único, me concentro en:
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={20}
        >
          <li>
            <strong>Gestión de Presupuesto:</strong> Proporcionando soluciones
            rentables sin comprometer la calidad, asegurando que incluso
            empresas con recursos limitados puedan beneficiarse de tecnología
            avanzada.
          </li>
          <li>
            <strong>Soluciones Pre-Configuradas:</strong> Ofreciendo prompts y
            herramientas de IA listas para implementar, acelerando la adopción y
            reduciendo tiempos de implementación.
          </li>
          <li>
            <strong>Profesionalismo:</strong> Garantizando transparencia,
            fiabilidad y excelencia en cada proyecto, construyendo confianza y
            asociaciones a largo plazo con los clientes.
          </li>
          <li>
            <strong>Soporte Técnico:</strong> Actuando como recurso técnico para
            equipos, brindando orientación y experiencia para ayudarlos a
            superar desafíos y lograr el éxito.
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={21}
        >
          Tu éxito es mi prioridad. Me esfuerzo por ser un socio accesible que
          realmente se preocupa por ayudar a tu negocio a prosperar. Adaptando
          soluciones a tus necesidades específicas, apunto a hacer que la
          tecnología avanzada sea accesible y beneficiosa para ti.
        </motion.p>

        {/* Colaboremos */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={22}
        >
          Colaboremos
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={23}
        >
          El futuro está lleno de posibilidades en IA y automatización. Ya sea
          que estés buscando optimizar procesos existentes, reducir costos
          operativos o embarcarte en proyectos innovadores, estoy aquí para
          ayudarte a navegar el panorama tecnológico. Mi experiencia en
          desarrollar algoritmos personalizados y gestionar equipos asegura que
          recibas un apoyo integral a lo largo de tu camino.
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={24}
        >
          No dudes en ponerte en contacto y exploremos cómo podemos convertir tu
          visión en realidad. Juntos, podemos aprovechar el poder de la
          tecnología para impulsar el crecimiento y el éxito.
        </motion.p>
      </div>

      <div className="contact-container">
        <h2 className="contact-title">Get in Touch</h2>
        <p className="contact-description">
          Hablemos sobre cómo podemos colaborar. Por favor, completa el
          formulario a continuación o contáctame directamente por correo
          electrónico.
        </p>

        {/* Contact Form */}
        <form
          action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
          method="POST"
          className="contact-form"
        >
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea
              id="message"
              name="message"
              rows="6"
              placeholder="Your Message"
              required
            ></textarea>
          </div>

          <button type="submit" className="contact-btn">
            Enviar Mensaje
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default About;
