import React from "react";
import { Element } from "react-scroll";
import SEO from "./SEO";
import "../App.css";
import Slideshow from "./Slideshow";
import Testimonials from "./Testimonials";

import img1 from "./slide1.webp";
import img2 from "./slide2.webp";
import img3 from "./slide3.webp";

import profileImg from "./me.jpeg";

import {
  FaSalesforce,
  FaCode,
  FaRobot,
  FaProjectDiagram,
  FaChartLine,
  FaCogs,
} from "react-icons/fa"; // Import some icons
import Menu from "./Menu";
import Footer from "./Footer";
import AINotifications from "./AINotifications";

function Home() {
  return (
    <>
      <SEO />
      <Menu />
      {/* Add a container for scroll snapping */}
      <div className="container">
        <Element
          id="home"
          name="home"
          className="section home"
          style={{ padding: 0 }}
        >
          <Slideshow>
            <div className="slide" key={1}>
              <img src={img1} alt="Diseño Web Potenciado por IA y Seguro" />
              <div className="text-overlay">
                <h1>
                  Tu web Ultra Rápida y Segura con diseño potenciado por IA
                </h1>
                <p>
                  Nos enfocamos en{" "}
                  <b>diseño web personalizado y potenciado por IA</b> para
                  garantizar experiencias únicas y optimizadas. Utilizamos
                  inteligencia artificial para adaptar la interfaz y
                  funcionalidad de cada sitio a las necesidades de los usuarios,
                  asegurando que cada elemento esté alineado con los objetivos
                  de tu negocio.
                </p>
                <p>
                  Nuestro enfoque "AI First" incorpora capas avanzadas de
                  seguridad impulsadas por IA, protegiendo tus datos y creando
                  una plataforma confiable para tus clientes.
                </p>

                <br />
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="/contacto"
                >
                  Más Información
                </a>
                <a
                  className="contact-btn"
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                >
                  LinkedIn
                </a>
              </div>
            </div>

            <div className="slide" key={2}>
              <img
                src={img2}
                alt="SEO y Publicidad Digital Optimizados por IA"
              />
              <div className="text-overlay">
                <h1>SEO y Publicidad Digital Optimizados por IA</h1>
                <p>
                  Lleva tu presencia en línea al siguiente nivel con nuestros
                  servicios de SEO y publicidad digital optimizados por IA.
                  Analizamos y ajustamos estrategias en tiempo real, gracias a
                  herramientas de inteligencia artificial que maximizan tu
                  visibilidad y aseguran que atraigas al público ideal.
                </p>
                <p>
                  Con IA, nuestras campañas de anuncios pagados se adaptan
                  dinámicamente para maximizar el rendimiento y mejorar las
                  conversiones, ayudándote a obtener resultados impactantes de
                  manera eficiente.
                </p>
                <a
                  className="contact-btn"
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                  style={{ marginRight: 10 }}
                >
                  LinkedIn
                </a>
                <a className="contact-btn" href="/sobre-mi">
                  Sobre Mí
                </a>
              </div>
            </div>

            <div className="slide" key={3}>
              <img src={img3} alt="Consultoría Estratégica con Enfoque en IA" />
              <div className="text-overlay">
                <h1>Consultoría Estratégica con Enfoque en IA</h1>
                <p>
                  Aprovecha el poder de la inteligencia artificial con nuestra{" "}
                  <b>consultoría estratégica orientada a IA</b>. Diseñamos
                  soluciones que optimizan procesos y aprovechan los datos para
                  decisiones más inteligentes, transformando tu negocio y
                  adaptándolo a la era digital.
                </p>
                <p>
                  Nuestro enfoque basado en IA asegura que cada recomendación
                  esté alineada con los últimos avances tecnológicos,
                  maximizando el rendimiento y permitiéndote estar a la
                  vanguardia de la innovación.
                </p>
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="/contacto"
                >
                  ¡Contáctanos!
                </a>
                <a className="contact-btn" href="/sobre-mi">
                  Sobre Mí
                </a>
              </div>
            </div>
          </Slideshow>
        </Element>
        <Element id="about" name="about" className="section about">
          <div className="profile-container">
            {/* Profile Section */}
            <div className="profile-section">
              <div className="profile-card">
                <img src={profileImg} alt="Renzo" className="profile-img" />
                <div className="profile-info">
                  <h2>Ingeniero Senior en Ciencias de la Computación</h2>
                  <p>
                    Experto en Implementación de IA e Ingeniero Senior en
                    Ciencias de la Computación con más de 16 años de experiencia
                    en desarrollo de software, gestión de proyectos y liderazgo
                    en TI. Me especializo en Salesforce, sistemas ERP y
                    desarrollo full-stack, con un historial comprobado de
                    liderar equipos multifuncionales y entregar soluciones
                    tecnológicas escalables para startups y empresas.
                  </p>
                  <p>Fluido en inglés y nativo en español.</p>
                  <p className="mobile-hidden">
                    Experto en Microsoft .NET, Salesforce, OpenAI, Node.js,
                    React, entre otros.
                  </p>

                  <a className="contact-btn mobile-hidden" href="/sobre-mi">
                    ¿Quieres saber más?
                  </a>
                </div>
              </div>
            </div>

            {/* Experience Section */}
            <Testimonials />
          </div>
        </Element>
        <Element
          id="attribution"
          name="attribution"
          className="section attribution"
        >
          <AINotifications />
        </Element>
        <Element id="services" name="services" className="section services">
          <div className="services-container">
            <h2 className="services-title">Servicios</h2>
            <div className="service-card">
              <FaRobot className="service-icon" />
              <h3>Diseño Web Potenciado por IA</h3>
              <p>
                Integramos herramientas de inteligencia artificial de vanguardia
                para crear sitios web personalizados que optimizan cada
                interacción. Nuestra IA potencia la automatización y análisis
                avanzados, permitiéndote tomar decisiones inteligentes basadas
                en datos y mejorar la experiencia del usuario sin los obstáculos
                comunes.
              </p>
            </div>
            <div className="service-card">
              <FaSalesforce className="service-icon" />
              <h3>Integración de IA en Salesforce</h3>
              <p>
                Eleva tu CRM Salesforce con integraciones de IA personalizadas,
                como Einstein Analytics, para optimizar procesos empresariales,
                mejorar la interacción con los clientes y simplificar
                operaciones. Accede a análisis predictivos y experiencias
                personalizadas directamente en tu plataforma CRM.
              </p>
            </div>
            <div className="service-card">
              <FaProjectDiagram className="service-icon" />
              <h3>Desarrollo de Algoritmos Avanzados</h3>
              <p>
                Diseñamos algoritmos a medida utilizando las últimas técnicas de
                inteligencia artificial y aprendizaje automático para optimizar
                flujos de trabajo, aumentar la eficiencia y mejorar la toma de
                decisiones. Nuestras soluciones son escalables y se adaptan a
                tus necesidades únicas, respetando tu presupuesto.
              </p>
            </div>
            <div className="service-card">
              <FaCogs className="service-icon" />
              <h3>Gestión de Sistemas y Eficiencia Potenciada por IA</h3>
              <p>
                Implementa y gestiona sistemas que impulsan la productividad
                operativa a través de herramientas impulsadas por IA, como la
                automatización de procesos robóticos (RPA) y la automatización
                inteligente. Simplifica procesos, reduce el esfuerzo manual y
                elimina costos innecesarios con la última tecnología.
              </p>
            </div>
            <div className="service-card mobile-hidden">
              <FaChartLine className="service-icon" />
              <h3>Inteligencia Empresarial Potenciada por IA</h3>
              <p>
                Desata el poder de tus datos con análisis impulsados por IA que
                ofrecen visualizaciones avanzadas y recomendaciones claras.
                Utilizamos big data y análisis predictivo para mantenerte
                competitivo y ayudarte a guiar tu negocio hacia el éxito.
              </p>
            </div>
            <div className="service-card mobile-hidden">
              <FaCode className="service-icon" />
              <h3>Desarrollo de Software Personalizado con IA</h3>
              <p>
                Ya sea comenzando desde cero o mejorando sistemas existentes,
                ofrecemos soluciones de software impulsadas por IA y adaptadas a
                las necesidades específicas de tu negocio. Nos enfocamos en la
                gestión de presupuesto y facilidad de uso, utilizando las
                últimas tendencias en desarrollo IA para asegurar escalabilidad
                y rendimiento.
              </p>
            </div>
          </div>
        </Element>
        <Element
          id="experience"
          name="experience"
          className="section experience"
        >
          <div className="experience-container">
            <h2 className="experience-title">Experiencia Profesional</h2>
            <div className="timeline">
              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2024 - Presente</span>
                  <h3>Ingeniero Senior en Ciencias de la Computación</h3>
                  <h4>Gargle Inc. (EE.UU.)</h4>
                  <p>
                    Como Ingeniero Senior en Ciencias de la Computación, mi
                    responsabilidad es planificar, diseñar y desarrollar
                    soluciones para los usuarios y clientes de Gargle. Mi
                    enfoque principal es contribuir en todos los aspectos
                    necesarios para asegurar que las operaciones diarias entre
                    departamentos funcionen de la manera más fluida posible,
                    además de integrar sistemas externos para automatizar
                    procesos y optimizar la entrega de nuevas soluciones.
                    <span className="mobile-hidden">
                      Además de esto, trato de contribuir al máximo para
                      proporcionar el mejor soporte posible a nuestros usuarios.
                    </span>
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2019 - 2024</span>
                  <h3>Director de Tecnología Contratado</h3>
                  <h4>DentalMarketing.net (EE.UU.)</h4>
                  <p>
                    Como CTO contratado, gestiono el equipo de ingeniería y
                    contribuyo directamente en el proceso de toma de decisiones
                    para soluciones de TI, analizando las necesidades a corto y
                    largo plazo de la organización para contribuir al logro de
                    sus objetivos.
                    <span className="mobile-hidden">
                      Me enfoqué en construir la estrategia de la compañía para
                      desarrollar aplicaciones web, integraciones de plataformas
                      y plataformas internas de operación, utilizando las
                      últimas tecnologías con el factor de costo-beneficio en
                      mente.
                    </span>
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2009 - 2019</span>
                  <h3>Gerente de Proyectos de TI (Internacional)</h3>
                  <h4>Credilit S.A.</h4>
                  <p>
                    <span className="mobile-hidden">
                      Credilit S.A. es una administradora de créditos con más de
                      40 años de experiencia operando en varios estados de
                      Uruguay.
                    </span>
                    Como Gerente de Proyectos de TI, mi responsabilidad era
                    gestionar proyectos para todas las sucursales y
                    departamentos de la empresa, como ventas, contabilidad,
                    marketing, entre otros, utilizando tecnologías como
                    Salesforce, ASP .NET, HTML, CSS y Javascript.
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2009 - 2019</span>
                  <h3>Líder de Equipo (Internacional)</h3>
                  <h4>Altimetrik</h4>
                  <p>
                    Lideré el Proyecto GEMINI de Salesforce en Altimetrik como
                    Líder de Equipo, supervisando todas las fases desde la
                    planificación hasta el despliegue y garantizando una entrega
                    oportuna y de calidad. Asistí en proyectos clave como
                    Campaña a Lead a través de revisiones de código, mentoría a
                    desarrolladores junior y la implementación de metodologías
                    Agile para mejorar la eficiencia del equipo y la calidad del
                    producto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Element>
        <Element id="contact" name="contact" className="section contact">
          <div className="contact-container">
            <h2 className="contact-title">Mantenete en Contacto</h2>
            <p className="contact-description">
              Hablemos sobre cómo podemos colaborar. Por favor, completa el
              formulario a continuación o contáctame directamente por correo
              electrónico.
            </p>

            {/* Contact Form */}
            <form
              action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
              method="POST"
              className="contact-form"
            >
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Mensaje</label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>

              <button type="submit" className="contact-btn">
                Enviar Mensaje
              </button>

              <br />
              <br />
              <Footer />
            </form>
          </div>
        </Element>
      </div>
    </>
  );
}

export default Home;
