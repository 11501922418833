import React from "react";
import Slider from "react-slick";

const Slideshow = ({ children }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: false,
  };

  return (
    <div className="slideshow">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default Slideshow;
