import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

function Testimonials() {
  const testimonialsData = [
    {
      id: 1,
      name: "Deidra Baker",
      title: "VP Of Process & People at Gargle - Present",
      image: "https://via.placeholder.com/100", // Replace with actual image URLs
      quote:
        "Renzo is the kind of co worker everybody wants to have! I have worked with him closely for the last 5 years to improve the processes and workflows at Gargle and DentalMarketing.Net. We collaborate daily to solve problems and provide resources to the company that increase efficiency, employee productivity and provide a better client experience to our customers. None of that would be possible without Renzo's skill set. He can solve any problem thrown at him and he will do it with a smile on his face. As an IT Project Manager for Gargle, he has built and managed numerous data systems and workflows and customized our Salesforce experience. He is natural leader and improves the culture of any company he is apart of.",
    },
    {
      id: 2,
      name: "Jacob Davis",
      title: "CTO at Gargle from 2019 to 2021",
      image: "https://via.placeholder.com/100",
      quote:
        "Renzo has proven himself as an asset to the business, he brings broad and unique experience to his role, and he has a solid understanding of business operations. He does a fantastic job melding together the technical and automation pieces to improve the company overall. Renzo has a great mix of technical talent and people skills that make him an invaluable asset to any organization. I was very comfortable having Renzo work directly with our clients, and then turn around and write the code necessary to implement solutions. He is always looking for ways to improve both the business and himself. ",
    },
  ];

  return (
    <section className="testimonials">
      <div className="testimonials-grid">
        {testimonialsData.map((testimonial) => (
          <div className="testimonial-card" key={testimonial.id}>
            <FaQuoteLeft className="quote-icon left" />
            <p className="testimonial-quote">{testimonial.quote}</p>
            <FaQuoteRight className="quote-icon right" />
            <div className="testimonial-info">
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-title">{testimonial.title}</p>
            </div>
          </div>
        ))}
      </div>
      <a
        className="contact-btn mobile-hidden"
        href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
      >
        Recommendations
      </a>
    </section>
  );
}

export default Testimonials;
