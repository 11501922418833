import React from "react";
import { Element } from "react-scroll";
import SEO from "./SEO";
import "../App.css";
import Menu from "./Menu";
import Footer from "./Footer";

function Contact() {
  return (
    <>
      <SEO />
      <Menu />
      {/* Add a container for scroll snapping */}
      <div className="container">
        <Element id="contact" name="contact" className="section contact">
          <div className="contact-container">
            <h2 className="contact-title">Mantenete en Contacto</h2>
            <p className="contact-description">
              Hablemos sobre cómo podemos colaborar. Por favor, completa el
              formulario a continuación o contáctame directamente por correo
              electrónico.
            </p>

            {/* Contact Form */}
            <form
              action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
              method="POST"
              className="contact-form"
            >
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Mensaje</label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>

              <button type="submit" className="contact-btn">
                Enviar Mensaje
              </button>
            </form>
          </div>
        </Element>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
