import React from "react";
import CodeEffect from "../CodeEffect";

const AINotifications = () => {
  return (
    <section className="algorithm-section">
      <div className="content">
        <h1 className="mobile-hidden">
          Diseños Web Personalizados para Tu Negocio ajustados a tu Presupuesto
        </h1>
        <h2>
          Enfocados en Precios Transparentes y un Servicio que Beneficia al
          Cliente
        </h2>
        <div className="services-grid">
          <CodeEffect
            arr={[
              "Diseño Web Personalizado",
              "Precios Accesibles y Transparentes",
              "Tu Web Ultra Rápida y Segura",
              "IA Integrada para Optimización",
            ]}
          />
          <CodeEffect
            arr={[
              "Optimización para Motores de Búsqueda (SEO)",
              "Desarrollo a Medida para Cada Proyecto",
              "Automatización de Tareas Repetitivas",
              "Soluciones únicas para cada Cliente",
            ]}
          />
          <CodeEffect
            arr={[
              "Diseños para Móviles y Tablets",
              "Análisis de Datos Avanzados",
              "Consultoría Estratégica de Tecnología",
              "Implementación Rápida y Eficiente",
            ]}
          />
        </div>
        <br />
        <p>
          Con nuestros servicios de diseño web, disfrutarás de precios
          accesibles y completamente transparentes, sin costos ocultos. Nuestro
          objetivo es potenciar tu negocio con diseños que optimicen la
          experiencia del usuario y se adapten a tus necesidades sin comprometer
          tu presupuesto.
          <br />
          <br />
          Además, nuestra solución es compatible con APIs para integrarse
          fácilmente en tus sistemas actuales, permitiendo una experiencia sin
          interrupciones. Desde el seguimiento de la efectividad de tus campañas
          hasta el monitoreo de la interacción de tus clientes, nuestro servicio
          está diseñado para brindarte ventajas competitivas, entregando
          insights cuando más los necesitas.
        </p>
        <br />
        <a className="contact-btn cta-button" href="/contacto">
          Solicita una Demo
        </a>
      </div>
    </section>
  );
};

export default AINotifications;
