import React from "react";
import SEO from "../SEO";
import "../../App.css";
import Menu from "../Menu";
import { motion } from "framer-motion";

// Import images (replace with actual paths)
import salesImage from "./sales1.webp";
import Footer from "../Footer";

function TopPromptsForManagers() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i = 1) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
      },
    }),
  };

  return (
    <>
      <SEO />
      <Menu />
      <div className="article-container">
        {/* Encabezado del Artículo */}
        <motion.h1
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={1}
          className="article-title"
        >
          Los 5 Mejores Prompts de IA para Gerentes en Ventas, Marketing y
          Optimización de Procesos
        </motion.h1>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={2}
          className="article-intro"
        >
          En el acelerado entorno empresarial actual, los gerentes están
          constantemente buscando formas de aumentar la productividad y fomentar
          el crecimiento. La inteligencia artificial (IA) ofrece herramientas
          poderosas para optimizar operaciones y tomar decisiones basadas en
          datos. Aquí están los 5 principales prompts de IA que todo gerente en
          ventas, marketing y optimización de procesos debería conocer.
        </motion.p>

        {/* Prompt 1 */}
        <motion.div
          className="prompt-section"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={6}
        >
          <img
            src={salesImage}
            alt="Estrategia de Ventas"
            className="prompt-image"
          />
          <h2>1. Análisis de Sentimiento en Feedback de Clientes</h2>
          <p>
            <strong>Copiar &amp; Pegar:</strong> <br />
            <div className="prompt">
              Analiza las opiniones y el feedback de los clientes para evaluar
              el sentimiento general hacia nuestros productos y servicios,
              destacando áreas de mejora. <br />
              <br />
              Evalúa esto desde nuestras reseñas en Google: [Ingresa el nombre
              de la empresa].
            </div>
          </p>
          <p>
            <strong>Por Qué Es Importante:</strong> Comprender el sentimiento de
            los clientes ayuda a los gerentes a abordar problemas de manera
            proactiva y mejorar la satisfacción y fidelidad de los clientes.
          </p>
          <p>
            <strong>Aplicación:</strong> Utiliza herramientas de procesamiento
            de lenguaje natural (NLP) impulsadas por IA para interpretar
            feedback cualitativo a gran escala.
          </p>
        </motion.div>

        {/* Prompt 2 */}
        <motion.div
          className="prompt-section"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={7}
        >
          <h2>2. Recomendaciones Personalizadas de Contenido</h2>
          <p>
            <strong>Copiar &amp; Pegar:</strong> <br />
            <div className="prompt">
              Desarrolla recomendaciones de contenido personalizado para los
              visitantes del sitio web en función de su historial de navegación
              y patrones de interacción. Adapta estas recomendaciones para
              captar la atención de [MERCADO OBJETIVO] de manera efectiva,
              mejorando la experiencia del usuario en el sitio web de [NOMBRE DE
              LA EMPRESA]. Proporciona tipos específicos de contenido para
              recomendar, como publicaciones de blog, páginas de productos o
              recursos, y explica cómo cada tipo se alinea con las preferencias
              del usuario para aumentar el compromiso.
            </div>
          </p>
          <p>
            <strong>Por Qué Es Importante:</strong> La personalización aumenta
            el compromiso, el tiempo en el sitio y la probabilidad de conversión
            al ofrecer contenido relevante para cada usuario.
          </p>
          <p>
            <strong>Aplicación:</strong> Implementa motores de recomendación de
            IA que se adapten en tiempo real al comportamiento del usuario,
            mejorando la experiencia del cliente.
          </p>
        </motion.div>

        {/* Prompt 3 */}
        <motion.div
          className="prompt-section"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={3}
        >
          <h2>3. Mejora de la Proyección de Ventas</h2>
          <p>
            <strong>Copiar &amp; Pegar:</strong> <br />
            <div className="prompt">
              Utilizando datos históricos de ventas, proyecta las tendencias de
              ventas para el próximo trimestre. Considera variaciones
              estacionales, condiciones actuales del mercado y factores externos
              como indicadores económicos o el rendimiento de la competencia.
              Proporciona un resumen de las tendencias esperadas, tasas de
              crecimiento o declive proyectadas e ideas esenciales para la toma
              de decisiones. Además, genera un gráfico claro y fácil de entender
              que ilustre estas predicciones. <br />
              <br />
              Nuestra empresa se especializa en [CAMBIAR: producto/servicio],
              específicamente dirigido a [CAMBIAR: enfoque de audiencia o
              mercado].
              <br />
              <br />
              Por favor, considera nuestra posición de mercado y cliente único:
              [Ingresa detalles relevantes].
            </div>
          </p>
          <p>
            <strong>Por Qué Es Importante:</strong> Una proyección de ventas
            precisa permite a los gerentes asignar recursos de manera efectiva,
            establecer objetivos realistas e identificar desafíos potenciales
            con anticipación.
          </p>
          <p>
            <strong>Aplicación:</strong> Utiliza algoritmos de IA para procesar
            grandes volúmenes de datos, descubrir patrones y proporcionar
            conocimientos accionables que el análisis manual podría pasar por
            alto.
          </p>
        </motion.div>

        {/* Prompt 4 */}
        <motion.div
          className="prompt-section"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={4}
        >
          <h2>4. Segmentación de Clientes para Marketing Dirigido</h2>
          <p>
            <strong>Copiar &amp; Pegar:</strong> <br />
            <div className="prompt">
              Segmenta nuestra base de clientes en grupos distintos analizando
              el comportamiento de compra, demografía y niveles de compromiso.
              Describe cada segmento con características clave y sugiere
              estrategias de marketing personalizadas para cada grupo para
              mejorar la efectividad de las campañas. Proporciona ideas
              accionables sobre cómo estos segmentos podrían influir en la
              efectividad del marketing y la retención de clientes. <br />
              <br />
              Nuestra empresa se especializa en [CAMBIAR: producto/servicio],
              específicamente dirigido a [CAMBIAR: enfoque de audiencia o
              mercado].
              <br />
              <br />
              Por favor, considera nuestra posición de mercado y cliente único:
              [Ingresa detalles relevantes].
            </div>
          </p>
          <p>
            <strong>Por Qué Es Importante:</strong> Comprender los segmentos de
            clientes permite esfuerzos de marketing personalizados, lo que
            resulta en mayores tasas de compromiso y conversión.
          </p>
          <p>
            <strong>Aplicación:</strong> Implementa algoritmos de clustering de
            IA para analizar los datos de clientes y crear segmentos
            significativos automáticamente.
          </p>
        </motion.div>

        {/* Prompt 5 */}
        <motion.div
          className="prompt-section"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={5}
        >
          <h2>
            5. Identificación de Oportunidades de Automatización de Procesos
          </h2>
          <p>
            <strong>Copiar &amp; Pegar:</strong> <br />
            <div className="prompt">
              Identifica tareas repetitivas y que consumen mucho tiempo dentro
              de nuestras operaciones que puedan automatizarse para mejorar la
              eficiencia y reducir costos. <br />
              <br />
              Por favor, evalúa este proceso: [Ingresa detalles relevantes].
            </div>
          </p>
          <p>
            <strong>Por Qué Es Importante:</strong> Automatizar tareas de rutina
            libera a los empleados para enfocarse en iniciativas estratégicas,
            aumentando la productividad general y la satisfacción laboral.
          </p>
          <p>
            <strong>Aplicación:</strong> Usa IA para analizar flujos de trabajo
            y recomendar oportunidades de automatización, integrando
            herramientas como la automatización de procesos robóticos (RPA).
          </p>
        </motion.div>

        {/* Conclusión */}
        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={8}
          className="article-conclusion"
        >
          Adoptar estos prompts de IA permite a los gerentes tomar decisiones
          basadas en datos, optimizar operaciones y mantenerse a la vanguardia
          en un mercado competitivo. Al integrar la IA en ventas, marketing y
          gestión de procesos, las organizaciones pueden desbloquear nuevos
          niveles de eficiencia e innovación.
        </motion.p>
      </div>

      <Footer />
    </>
  );
}

export default TopPromptsForManagers;
