import React from "react";
import { Helmet } from "react-helmet";

function SEO() {
  return (
    <Helmet>
      <title>
        BenjaminLabs | Tu web Ultra Rápida y Segura con diseño potenciado por IA
      </title>
      <meta
        name="description"
        content="Aprovecha el poder de la inteligencia artificial con nuestra consultoría estratégica orientada a IA"
      />
      <meta
        name="keywords"
        content="Consultor de TI, Desarrollador Salesforce, Especialista en ERP, Desarrollador Full-Stack, Desarrollo de Software Personalizado, Integración de IA y Automatización, Consultoría para Startups y Empresas, Inteligencia de Negocios Potenciada por IA, Consultoría en Diseño Web y SEO, Marketing Digital y Optimización de Datos"
      />
      <meta name="author" content="Renzo" />
    </Helmet>
  );
}

export default SEO;
